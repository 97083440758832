body{
    font-family: 'Knockout', sans-serif;
    margin: 0;
    padding: 0;
    color: #ffffff;
    background-color: #161616;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden !important;
}
a, a:focus, .link, .link:focus{
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 2em;
    margin: 0;
}
a:hover , a:active, .link:hover , .link:active{
    color: #ccff00;
}
h1{
    font-weight: 900;
    font-size: 4.5vw;
    color: #ccff00;
    font-weight: bold;
    text-transform: uppercase;
}
button, button:focus, button:active{
    margin-top: 1em;
    width: min-content;
    white-space: nowrap;
    background-color: transparent;
    border: #ccff00;
    border-radius: 10em;
    border-style: solid;
    border-width: 2px 2px;
    color: #ffffff;
    padding: 0.3em 1em;
    font-weight: 600;
    background-color: #ccff00;
    color: #0e0e0e;
    font-size: 200%;
}
button:hover{
    background-color: #0e0e0e;
    color: #ccff00;
    transition: 0.1s;
}
button > div{
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 768px) {
    body{
        font-size: 10px;
        font-weight: 500;
    }
    a, a:focus, .link, .link:focus{
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1em;
    }
    h1{
        font-size: 1.2em;
    }
    button, button:focus, button:active{
        margin-top: 0.5em;
        border-radius: 3em;
        border-width: 1px 1px;
        transform: scaleY(1);
        font-size: 3vw;
    }
    button > div{
        transform: scaleY(1);
    }
}

p, h1, h2, h3, h4, h5, h6{
    white-space: pre-line;
}

h1{
    font-family: 'Avenir Next LT Pro', sans-serif;
    font-size: 4.5vw;
}
p{
    font-size: 1.1vw;
}
@media screen and (max-width: 768px) {
    p{
        font-size: 2.7vw;
    }
    h1{
        font-size: 8vw;
    }
}


/* hero style */
.carousel_canvas, .hero-container{
    position: relative;
    top: 0;
    height: calc(45vw + 10em);
    width: 100%;
    overflow: hidden;
    transition: 1s !important;
}
.carousel_canvas > section{
    width: 800vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow: hidden !important;
}
.hero{
    display: block;
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-width: 100vw;
    height: 100%;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    transition: 1s;
}
.hero > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 10em;
    bottom: 0;
    left: 5%;
    right: 52%;
}
.hero > div > h1{
    font-size: 10vw;
    line-height: 0.8;
    margin: 0;
}
.hero > div > p{
    font-size: 2vw;
    margin: 0;
    padding-left: 0.15em;
}
@media screen and (max-width: 768px) {
    .hero-container{
        height: calc(50vw + 8em);
    }
    .hero > div > h1{
        font-size: 8vw;
        line-height: 0.8;
        margin: 0;
    }
    .hero > div{
        position: absolute;
        top: 8em;
        bottom: 0;
        left: 3%;
        right: 58%;
        transform: none;
    }
    .hero > div > p{
        font-size: 2.7vw;
    }
}


@keyframes imageListScroll {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-25%);
    }
}

.servizio{
    width: 100%;
    height: 37.5vw;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 0;
    background-color: #000000aa;
    background-blend-mode: color;
}
.servizio > h1{
    text-align: center;
    text-transform: none;
    margin: 0 7% 0 7%;
}
.servizio > p{
    text-align: center;
    margin: 0 7% 0 7%;
}
@media screen and (max-width: 768px) {
}